<script lang="ts" src="./Avatar.ts"></script>

<template>
  <div :class="clss">
    <div :class="['avatar', { default: showDefault || !url }]">
      <img :alt="alt" :src="(!showDefault && url) ? url : `${$settings.appPath}/images/logo.svg`" @error="replaceWithDefault"/>
    </div>

    <div v-if="showEditButton" class="badge-wrapper">
      <m-button
        :appearance="$const.StyleAppearance.PRIMARY"
        :icon-before="{ name: 'edit', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
        :size="$const.StyleSize.XSMALL"
        :variant="$const.StyleVariants.GHOST"
        class="ml-3"
        @click="changeAvatar()"
      >
      </m-button>
    </div>

    <div class="badge-wrapper -bottom-0.5">
      <template v-if="partner">
        <m-tooltip :label="$trans.t('app.tooltips.partnerStatus')" class="tooltip">
          <partner-badge class="partner-badge"/>
        </m-tooltip>
      </template>

      <template v-else-if="verified">
        <m-tooltip :label="$trans.t('app.tooltips.verifiedStatus')" class="tooltip">
          <verified-badge class="verified-badge"/>
        </m-tooltip>
      </template>
    </div>

    <span v-if="notification" class="notification"></span>

    <m-avatar-modal ref="avatarModal"/>
  </div>
</template>


<style lang="scss" scoped>
</style>
