<script lang="ts" src="./ServiceCard.ts"></script>

<template>
  <div :class="['flex flex-1 flex-col p-4 relative border rounded-xl bg-neutral-0', hasShadow ? 'shadow-md hover:shadow-lg transition-shadow' : '']">

    <div v-if="service.profileImages?.length > 0" class="h-48 flex space-x-3">
      <div class="h-full flex-1 relative">
        <figure class="figure">
          <template v-if="service.profileImages[0].thumbUrl">
            <img
              :alt="service.profileImages[0].fileName"
              :src="service.profileImages[0].thumbUrl"
              class="image"
            />
          </template>
          <template v-else>
            <m-icon :size="$const.StyleSize.XLARGE" :solid="true" :type="$const.IconTypes.HERO" class="missing-image-icon" name="photo"/>
          </template>
        </figure>
      </div>

      <ul v-if="service.profileImages?.length > 1" class="flex flex-col space-y-3">
        <li v-for="(image, index) in service.profileImages.slice(1, 3)" :key="image.uuid" class="w-20 h-20 flex-1 relative">
          <figure class="figure">
            <template v-if="image.thumbUrl">
              <img
                :alt="image.fileName"
                :src="image.thumbUrl"
                class="image"
              />
            </template>
            <template v-else>
              <m-icon :size="$const.StyleSize.XLARGE" :solid="true" :type="$const.IconTypes.HERO" class="missing-image-icon" name="photo"/>
            </template>
          </figure>
        </li>
      </ul>
    </div>

    <div v-else class="h-48 relative">
      <figure class="figure">
        <m-icon :size="$const.StyleSize.XLARGE" :solid="true" :type="$const.IconTypes.HERO" class="missing-image-icon" name="photo"/>
      </figure>
    </div>

    <div class="flex flex-col flex-1 my-4 space-y-4">
      <div class="space-y-1">
        <m-badge :appearance="$const.StyleAppearance.PRIMARY" :size="$const.StyleSize.SMALL">
          <m-icon :name="service.serviceCategory" :parse-to="IconParseTypes.SERVICE_CATEGORY" :size="$const.StyleSize.XSMALL" :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="my-auto mr-1"/>
          {{ $trans.t('meta.serviceCategoryTypes.' + service.serviceCategory) }}
        </m-badge>
        <h3 class="font-body line-clamp-1">
          <m-link
            :new-window="true"
            :to="{ name: 'seoServiceRead', params: { slug: service.slug } }"
            v-html="service.name.value"
          />
        </h3>
        <p class="text-tiny leading-tight line-clamp-5" v-html="service.description.value"></p>
      </div>
    </div>

    <m-button
      :appearance="$const.StyleAppearance.NEUTRAL_NEW"
      :icon-before="{ name: 'eye', solid: true, type: $const.IconTypes.HERO, }"
      :new-window="true"
      :size="$const.StyleSize.SMALL"
      :to="{ name: 'seoServiceRead', params: { slug: service.slug } }"
    >
      {{ $trans.t('app.buttonLabels.readMore') }}
    </m-button>

  </div>
</template>

<style lang="scss" scoped>

.figure {
  @apply h-full flex items-center justify-center overflow-hidden relative rounded-xl border bg-neutral-10 bg-opacity-40;

  .image {
    @apply min-w-full h-full object-cover;
  }
}

.missing-image-icon {
  @apply w-14 h-14 text-neutral-30;
}

</style>
