import {Component, Prop, Vue} from 'vue-facing-decorator';
import {StyleAppearance} from '@/app/constants/styles/StyleAppearance';
import {StyleShapes} from '@/app/constants/styles/StyleShapes';
import {StyleSize} from '@/app/constants/styles/StyleSize';
import PartnerBadge from '@/app/views/components/misc/PartnerBadge.vue';
import VerifiedBadge from '@/app/views/components/misc/VerifiedBadge.vue';
import AvatarModal from '@/app/views/modals/AvatarModal';
import AvatarModalVue from '@/app/views/modals/AvatarModal.vue';

@Component({
    components: {
        'm-avatar-modal': AvatarModalVue,
        'partner-badge': PartnerBadge,
        'verified-badge': VerifiedBadge,
    },
})
export default class Avatar extends Vue {

    @Prop({type: String, default: null})
    public readonly alt: string;

    @Prop({type: String, default: null})
    public readonly url: string;

    @Prop({type: Boolean, default: false})
    public readonly partner: boolean;

    @Prop({type: Boolean, default: false})
    public readonly verified: boolean;

    @Prop({type: Boolean, default: false})
    public readonly notification: boolean;

    public showDefault: boolean = false;

    declare public $refs: {
        avatarModal: AvatarModal;
    };

    @Prop({type: Boolean, default: false})
    private readonly interactive: boolean;

    @Prop({type: String, default: StyleAppearance.PRIMARY})
    private readonly appearance: StyleAppearance;

    @Prop({type: String, default: StyleShapes.DEFAULT})
    private readonly shape: StyleShapes;

    @Prop({type: String, default: StyleSize.MEDIUM})
    private readonly size: StyleSize;

    @Prop({type: Boolean, default: false})
    private readonly showEditButton: boolean;

    public get clss(): string {
        let clss: string = 'avatar-wrapper';
        if (this.appearance) {
            clss += ' ' + this.appearance;
        }
        if (this.size) {
            clss += ' ' + this.size;
        }
        if (this.shape) {
            clss += ' ' + this.shape;
        }
        if (this.interactive) {
            clss += ' interactive';
        }

        return clss;
    }

    public replaceWithDefault(e): void {
        this.showDefault = true;

        e.target.src = 'frontend/images/logo.svg';
        e.target.onerror = null;
    }

    public changeAvatar(): void {
        this.$refs.avatarModal.show();
    }
}
