import {CastableProperties} from "@/app/utils/Castable";
import {CompanyShortDTO} from "@/app/dto/CompanyShortDTO";
import {FileDTO} from "@/app/dto/FileDTO";
import {TranslationDTO} from "@/app/dto/TranslationDTO";

export class PublicSellerShortDTO extends CompanyShortDTO {

    public introductionBrief: TranslationDTO = new TranslationDTO();

    public manufyValues: string[] = [];

    public fabrics: string[] = [];
    public categories: string[] = [];
    public serviceCategories: string[] = [];

    public profileImages: FileDTO[] = null;

    public objectID: string = null;

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
            introductionBrief: TranslationDTO,
            profileImages: [FileDTO],
        };
    }
}
