import Bluebird from 'bluebird';
import {Component} from 'vue-facing-decorator';
import {Main} from '@/app/Main';
import {FileDTO} from '@/app/dto/FileDTO';
import {Lang} from '@/app/lang/Lang';
import {Store} from '@/app/stores/Store';
import {FormData} from '@/app/utils/FormData';
import {FormRulesById} from '@/app/utils/FormRules';
import FormFileUpload from '@/app/views/components/form/FormFileUpload';
import {Modal} from '@/app/views/modals/Modal';
import {RequestMethods} from '@/libs/core/constants/RequestMethods';
import {Uuid} from '@/types/Types';
import {Routes} from "@/app/Routes";

type AvatarFormModel = {
    _avatarFiles?: FileDTO[],
};

@Component({})
export default class AvatarModal extends Modal {

    declare public readonly $refs: {
        fileUpload: FormFileUpload;
    };

    public readonly flags = {
        ...super.flags,
        loading: false,
    };

    public formData: FormData<AvatarFormModel> = null;
    private currentAvatarFile: FileDTO = null;

    public get formChanged(): boolean {
        const newUuid: Uuid = this.formData.model._avatarFiles[0]?.uuid ?? null;
        return (newUuid != this.currentAvatarFile?.uuid);
    }

    public get isSubmitDisabled(): boolean {
        return (this.flags.loading) || (this.$refs.fileUpload?.erroneousFiles?.length > 0) || (this.$refs.fileUpload?.numFilesLoading > 0) || (!this.formChanged);
    }

    public created(): void {
    }

    public mounted(): void {
    }

    public show(projectUuid: Uuid = null): void {
        // define rules:
        const formRules: FormRulesById = {};

        // Reset form:
        this.formData = new FormData({}, formRules);

        this.flags.loading = true;

        const payload: Record<string, any> = {};
        payload.uuid = Store.user.currentCompanyUuid;

        let promise: Bluebird<any> = Main.callApi('company/avatar', RequestMethods.GET, payload, FileDTO);
        promise = promise.then(this.handleResult.bind(this));
        promise = promise.finally(() => {
            this.flags.loading = false;
        });

        this.flags.show = true;
    }

    public submit(): void {
        // Nothing to validate..

        const payload: Record<string, any> = {};

        payload.companyUuid = Store.user.currentCompanyUuid;
        payload.temporaryFileUuid = this.formData.model._avatarFiles[0]?.uuid ?? null;
        if (!payload.temporaryFileUuid) {   // If there is no new uuid then delete the old file
            payload.mediaUuid = this.currentAvatarFile?.uuid;
        }

        const method: RequestMethods = (payload.temporaryFileUuid) ? RequestMethods.POST : RequestMethods.DELETE;

        this.flags.loading = true;
        let promise: Bluebird<any> = Main.callApi('company/avatar', method, payload, FileDTO);
        promise = promise.then(this.handleResult.bind(this));
        promise = promise.then(() => {
            Main.app.toast.success(Main.trans.t(Lang.t.app.toast.success.avatarUploaded.message), Main.trans.t(Lang.t.app.toast.success.avatarUploaded.title), {preventDuplicates: false});
        });
        promise = promise.finally(() => {
            this.flags.loading = false;
            this.hide(true);

            Routes.refreshPage();
        });
    }

    private handleResult(result: FileDTO): void {
        this.currentAvatarFile = result;
        this.formData.model._avatarFiles = (result) ? [result] : [];

        Store.company.reloadSettings();
    }

}
