import {Component, Prop, Vue} from 'vue-facing-decorator';
import {StyleAppearance} from "@/app/constants/styles/StyleAppearance";
import {StyleShapes} from "@/app/constants/styles/StyleShapes";
import {StyleSize} from "@/app/constants/styles/StyleSize";
import {StyleVariants} from "@/app/constants/styles/StyleVariants";
import {IconParseTypes} from "@/app/constants/IconParseTypes";
import {ServiceCategoryTypes} from "@/app/constants/ServiceCategoryTypes";

@Component({})
export default class ServiceCategoryIcons extends Vue {

    @Prop({type: Array, default: []})
    public readonly serviceCategories: string[];

    @Prop({type: Number, default: 3})
    public readonly maxIcons: number;

    @Prop({type: String, default: StyleAppearance.PRIMARY})
    public readonly appearance: StyleAppearance;

    @Prop({type: Boolean, default: true})
    public readonly isLight: boolean;

    @Prop({type: String, default: StyleShapes.SQUARE})
    public readonly shape: StyleShapes = null;

    @Prop({type: String, default: StyleVariants.OUTLINE})
    public readonly variant: StyleVariants;

    @Prop({type: [String, Object], default: StyleSize.MEDIUM})
    public readonly size: StyleSize | Object;

    @Prop({type: Boolean, default: false})
    public readonly withLabel: boolean;

    public readonly IconParseTypes = IconParseTypes;

    public get hasShape(): void {
        return !this.withLabel ? this.shape : null
    }

    public getParsedValue(value): string {
        switch (value) {
            case ServiceCategoryTypes.DESIGN_DEVELOPMENT:
                return 'pen-ruler';
            case ServiceCategoryTypes.SOURCING:
                return 'scroll';
            case ServiceCategoryTypes.MANUFACTURING:
                return 'scissors';
            case ServiceCategoryTypes.PACKAGING_LABELING:
                return 'tags';
            case ServiceCategoryTypes.RETURN_REDUCTION:
                return 'right-left';
            case ServiceCategoryTypes.LOGISTICS_SHIPPING:
                return 'dolly';
            case ServiceCategoryTypes.REPAIR:
                return 'bandage';
            case ServiceCategoryTypes.RESALE:
                return 'share-from-square';
            case ServiceCategoryTypes.UPCYCLING:
                return 'turn-up';
            case ServiceCategoryTypes.RECYCLE:
                return 'recycle';
            case ServiceCategoryTypes.TRACEABILITY:
                return 'magnifying-glass';
            case ServiceCategoryTypes.REPORTING:
                return 'clipboard-list';
            case ServiceCategoryTypes.CERTIFICATION:
                return 'certificate';
        }
    }
}
