<script lang="ts" src="./AvatarModal.ts"></script>


<template>
  <div v-if="flags.show" class="modal viewport-scroll" @click.self="hide()">
    <div class="modal-dialog w-large secondary">
      <div class="dialog-header">
        <h2 class="title">
          {{ $trans.t('app.avatarModal.title') }}
        </h2>

        <a class="close" href="#" @click.prevent="hide()">
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="xmark"/>
        </a>
      </div>

      <form @submit.prevent="submit()">
        <div class="m-form dialog-body">
          <m-form-file-upload-wrapper
            id="avatar"
            v-model="formData.model._avatarFiles"
            :accept="$const.FileMimes.IMAGES"
            :aspectRatio="1/1"
            :errors="formData.errors._avatarFile"
            :label="$trans.t('app.form.avatar.label')"
          />
        </div>

        <div class="dialog-footer m-form__action-buttons">
          <m-cancel-button :size="$const.StyleSize.SMALL" @cancel="hide()"/>
          <m-submit-button
            :disabled="isSubmitDisabled"
            :size="$const.StyleSize.SMALL"
            label="app.buttonLabels.update"
          />
        </div>
      </form>
    </div>
  </div>
</template>


<style lang="scss" scoped>

.dialog-footer {
  @apply flex-wrap justify-end;
}

</style>
