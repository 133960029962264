import {Component, Prop, Vue} from 'vue-facing-decorator';
import {RouteLocationRaw} from 'vue-router';
import {Align} from '@/app/constants/styles/Align';
import {StyleAppearance} from '@/app/constants/styles/StyleAppearance';
import {StyleShapes} from '@/app/constants/styles/StyleShapes';
import {StyleSize} from '@/app/constants/styles/StyleSize';
import {StyleVariants} from '@/app/constants/styles/StyleVariants';
import {IIcon} from '@/app/views/components/misc/Icon';
import {useSlots} from 'vue';

@Component({})
export default class Button extends Vue {

    @Prop({type: [String, Object], default: null})
    public readonly to: RouteLocationRaw;

    @Prop({type: Boolean, default: false})
    public readonly isSubmit: boolean;

    @Prop({type: Boolean, default: false})
    public readonly newWindow: boolean;

    @Prop({type: Object, default: null})
    public readonly iconBefore: IIcon;

    @Prop({type: Object, default: null})
    public readonly iconAfter: IIcon;

    @Prop({type: Object, default: null})
    public readonly icon: IIcon;

    @Prop({type: Boolean, default: false})
    public readonly flexButton: boolean;

    @Prop({type: Boolean, default: false})
    public readonly fullWidth: boolean;

    @Prop({type: Boolean, default: false})
    public readonly disabled: boolean;

    @Prop({type: String, default: Align.CENTER})
    private readonly contentAlignment: Align;

    @Prop({type: String, default: StyleVariants.DEFAULT})
    private readonly variant: StyleVariants;

    @Prop({type: String, default: StyleAppearance.DARK})
    private readonly appearance: StyleAppearance;

    @Prop({type: String, default: StyleShapes.DEFAULT})
    private readonly shape: StyleShapes;

    @Prop({type: [String, Object], default: StyleSize.MEDIUM})
    private readonly size: StyleSize | Object;

    @Prop({type: Boolean, default: false})
    private readonly isLight: boolean;

    @Prop({type: Boolean, default: false})
    private readonly isDark: boolean;

    @Prop({type: Boolean, default: false})
    private readonly sharp: boolean;

    @Prop({type: Boolean, default: false})
    private readonly hidden: boolean;

    private slots = useSlots();

    public get clss(): string {
        let clss: string = 'btn';
        if (this.appearance) {
            clss += ' ' + this.appearance;
        }
        if (this.size) {
            if (typeof this.size === 'object') {
                for (let key in this.size) {
                    if (key !== 'default') {
                        clss += ' ' + key + ':' + this.size[key];
                    } else {
                        clss += ' ' + this.size[key];
                    }
                }
            } else {
                clss += ' ' + this.size;
            }

        }
        if (this.shape) {
            clss += ' ' + this.shape;
        }
        if (this.variant) {
            clss += ' ' + this.variant;
        }
        if (this.isLight) {
            clss += ' is-light';
        }
        if (this.isDark) {
            clss += ' is-dark';
        }
        if (this.disabled) {
            clss += ' disabled';
        }

        if (this.flexButton) {
            clss += ' flex-button';
        }

        if (this.sharp) {
            clss += ' sharp';
        }

        if (this.fullWidth) {
            clss += ' full-width';
        }

        if (this.contentAlignment) {
            clss += ' content-' + this.contentAlignment;
        }

        if (this.hidden) {
            clss += ' invisible';
        }

        return clss;
    }

}
