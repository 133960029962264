<script lang="ts" src="./FormFileUpload.ts"></script>


<template>
  <div
    :class="[
      'form-field-group',
      {
        'has-error': (errors?.length > 0),
        'has-data': (value?.length > 0),
        disabled: disabled,
      },
    ]"
  >
    <label
      :class="['form-field', {
        'entering': flags.entering,
        'not-allowed': flags.notAllowed,
      }]"
      :for="id"
      @dragenter.prevent="handleDrag"
      @dragleave.prevent="handleDragLeave"
      @dragover.prevent="handleDrag"
      @drop.prevent="handleFilesDropped"
    >
      <input
        :id="id"
        ref="field"
        :accept="accept"
        :disabled="disabled"
        :multiple="multiple"
        class="input"
        type="file"
        @input="handleFilesSelected($event)"
      />

      <m-icon
        :solid="true"
        :type="$const.IconTypes.FONT_AWESOME"
        class="file-icon"
        name="file-arrow-up"
      />

      <div class="text-wrapper">
        <p class="title" v-html="$trans.t((multiple) ? 'app.labels.uploadFiles' : 'app.labels.uploadFile')"></p>
        <p class="support-text">{{ acceptedFileTypes }}</p>
      </div>
    </label>

    <m-form-error v-show="flags.notAllowed" :errors="[$trans.t('app.labels.uploadSingleFile')]"/>
    <m-form-error :errors="errors"/>

    <div v-if="modelValue?.length > 0|| erroneousFiles.length > 0" class="flex items-center mt-1 -mb-3">
      <p class="text-tiny flex-1">{{ modelValue.length }} {{ $trans.t('app.labels.uploadedFiles') }}</p>
      <m-button
        :appearance="$const.StyleAppearance.PRIMARY"
        :size="$const.StyleSize.SMALL"
        :variant="$const.StyleVariants.TEXT"
        @click="removeAllFiles()"
      >
        {{ $trans.t('app.labels.removeAll') }}
      </m-button>
    </div>

    <draggable
      :list="modelValue"
      class="flex flex-col -mb-4 mt-4"
      handle=".sort-handle"
      item-key="uuid"
      tag="ul"
      @choose="flags.fileDragDisabled=true"
      @end="flags.fileDragDisabled=false"
      @unChoose="flags.fileDragDisabled=false"
    >
      <template #item="{element}">
        <li v-if="!element.errors" class="pb-4">
          <div class="m-file">
            <m-icon v-if="modelValue.length > 1" :solid="true" :type="$const.IconTypes.HERO" class="sort-handle mr-3" name="bars-4"/>
            <img v-if="element.getThumbUrl()" :src="element.getThumbUrl()" class="thumbnail"/>
            <div v-else class="w-8 text-center">
              <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="icon" name="paperclip"/>
            </div>

            <!-- TODO: show loader when (file.promise != null) -->
            <span class="filename">
              <span class="mr-1">{{ element.fileName }}</span>
              <span v-if="!element.fileSize" class="relative ml-3">
                <span class="m-preload-spinner secondary x-small"></span>
              </span>
              <small v-if="element.fileSize" class="text-sm text-neutral-80">({{ element.fileSize }})</small>
            </span>

            <m-button
              :appearance="$const.StyleAppearance.DANGER"
              :shape="$const.StyleShapes.SQUARE"
              :size="$const.StyleSize.SMALL"
              :icon="{ name: 'trash', solid: true, type: $const.IconTypes.FONT_AWESOME}"
              @click="deleteFile(element)"
            />
          </div>
        </li>
      </template>
    </draggable>

    <ul class="flex flex-col -mb-4 mt-4">
      <li v-for="element in erroneousFiles" :key="element.uuid" class="pb-4">
        <div class="m-file has-errors">
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="icon" name="paperclip"/>

          <span class="filename">{{ element.fileName }} <template v-if="element.fileSize">({{ element.fileSize }})</template></span>

          <m-button
            :appearance="$const.StyleAppearance.DANGER"
            :shape="$const.StyleShapes.SQUARE"
            :size="$const.StyleSize.SMALL"
            :icon="{ name: 'trash', solid: true, type: $const.IconTypes.FONT_AWESOME}"
            @click="deleteFile(element)"
          />
        </div>

        <m-form-error :errors="element.errors"/>
      </li>
    </ul>
  </div>
</template>


<style lang="scss" scoped>

.form-field {
  @apply flex flex-col items-center justify-center p-6 relative border-2 border-dashed cursor-pointer transition-colors;

  &.entering {
    @apply bg-green-25 border-green-75;
  }

  &.not-allowed {
    @apply bg-red-25 border-red-75;

    .file-icon {
      @apply text-red-400;
    }

    .text-wrapper .title :deep(strong) {
      @apply text-red-400;
    }
  }

  .input {
    @apply hidden absolute top-0 left-0;
  }

  .file-icon {
    @apply text-4xl text-green-400 transition-colors;
  }

  .text-wrapper {
    @apply mt-3 text-center xs:max-w-sm sm:max-w-md;

    .title {
      @apply text-base;
    }

    .support-text {
      @apply mt-1 text-tiny leading-tight text-neutral-80;
    }
  }
}

.sort-handle {
  cursor: grab;
}

</style>
