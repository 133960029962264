<script lang="ts" src="./Button.ts"></script>

<template>
  <m-link v-if="to" :class="clss" :disabled="disabled ? 'disabled' : null" :newWindow="newWindow" :to="to">
    <div class="content">
      <div :class="[$slots.default ? 'flex flex-row  justify-center' : '']">
        <m-icon v-if="iconBefore" :class="['icon before', 'type-' + iconBefore?.name]" :name="iconBefore.name" :size="iconBefore.size" :solid="iconBefore.solid" :type="iconBefore.type"/>
        <div v-if="$slots.default" class="my-auto">
          <slot></slot>
        </div>
        <m-icon v-if="icon" :class="['icon', 'type-' + icon?.name]" :name="icon.name" :size="icon.size ?? $const.StyleSize.LARGE" :solid="icon.solid" :type="icon.type"/>
        <m-icon v-if="iconAfter" :class="['icon after', 'type-' + iconAfter?.name]" :name="iconAfter.name" :size="iconAfter.size" :solid="iconAfter.solid" :type="iconAfter.type"/>
      </div>
    </div>
  </m-link>
  <button v-else-if="isSubmit" :class="clss" :disabled="disabled" type="submit">
    <m-icon v-if="iconBefore" :class="['icon before', 'type-' + iconBefore?.name]" :name="iconBefore.name" :size="iconBefore.size" :solid="iconBefore.solid" :type="iconBefore.type"/>
    <slot></slot>
    <m-icon v-if="iconAfter" :class="['icon after', 'type-' + iconAfter?.name]" :name="iconAfter.name" :size="iconAfter.size" :solid="iconAfter.solid" :type="iconAfter.type"/>
  </button>
  <a v-else :class="clss">
    <div class="content">
      <div :class="[$slots.default ? 'flex flex-row justify-center' : '']">
        <m-icon v-if="iconBefore" :class="['icon before', 'type-' + iconBefore?.name]" :name="iconBefore.name" :size="iconBefore.size" :solid="iconBefore.solid" :type="iconBefore.type"/>
        <div v-if="$slots.default" class="my-auto">
          <slot></slot>
        </div>
        <m-icon v-if="icon" :class="['icon', 'type-' + icon?.name]" :name="icon.name" :size="icon.size ?? $const.StyleSize.LARGE" :solid="icon.solid" :type="icon.type"/>
        <m-icon v-if="iconAfter" :class="['icon after', 'type-' + iconAfter?.name]" :name="iconAfter.name" :size="iconAfter.size" :solid="iconAfter.solid" :type="iconAfter.type"/>
      </div>
    </div>
  </a>
</template>

<style lang="scss" scoped>
::v-slotted(svg) {
  @apply inline-block leading-tight;
}
</style>
