import {Component} from 'vue-facing-decorator';
import {Modal} from '@/app/views/modals/Modal';
import {Routes} from "@/app/Routes";

@Component({})
export default class CategoryListModal extends Modal {

    public readonly flags = {
        ...super.flags,
        loading: false,
    };

    public categories: Array<string>;

    public created(): void {
    }

    public mounted(): void {
    }

    public show(categories: Array<string>): void {
        this.categories = categories;

        this.flags.show = true;
    }

    public showSellersWithCategory(category): void {
        Routes.resolveLink({name: 'sourcingProviderList', query: {cat: category}}, true)
    }
}
