import Bluebird from 'bluebird';
import {Main} from '@/app/Main';
import {CompanyLayoutDTO} from '@/app/dto/CompanyLayoutDTO';
import {FileDTO} from '@/app/dto/FileDTO';
import {Store} from '@/app/stores/Store';
import {StoreBase} from '@/app/stores/StoreBase';
import {RequestMethods} from '@/libs/core/constants/RequestMethods';
import {Lang} from "@/app/lang/Lang";
import {CompanyAvailabilityStatus} from '@/app/constants/CompanyAvailabilityStatus';

interface ICompanyState {
    name: string;
    slug: string;
    avatar: FileDTO;
    registrationCountry: string;
    unreadMessagesCount: number;
    subscriptionType: string;
    availabilityStatus: CompanyAvailabilityStatus;
    isVerified: boolean;
}

/**
 *
 */
export class CompanyStore extends StoreBase<ICompanyState> {

    constructor() {
        super();
    }

    public get name(): string {
        return this.state.name;
    }

    public get slug(): string {
        return this.state.slug;
    }

    public get avatar(): FileDTO {
        return this.state.avatar;
    }

    public get registrationCountry(): string {
        return this.state.registrationCountry;
    }

    public get unreadMessagesCount(): number {
        return this.state.unreadMessagesCount;
    }

    public set unreadMessagesCount(value: number) {
        this.state.unreadMessagesCount = value;
    }

    public get subscriptionType(): string {
        return this.state.subscriptionType;
    }

    public get availabilityStatus(): CompanyAvailabilityStatus {
        return this.state.availabilityStatus;
    }

    public set availabilityStatus(availabilityStatus: CompanyAvailabilityStatus) {
        if (!Store.user.user?.companyUuid) {
            return;
        } // No need to update anything

        // Save server side:
        const payload: Record<string, any> = {};
        payload.uuid = Store.user.currentCompanyUuid;
        payload.status = availabilityStatus;

        let promise: Bluebird<any> = Main.callApi('company/status', RequestMethods.PATCH, payload, CompanyLayoutDTO);
        promise = promise.then(this.handleUpdateStatusResult.bind(this))
        promise = promise.finally(this.reloadSettings.bind(this));  // TODO: Handle fail. For now fail gracefully in case of error
    }

    public get isVerified(): boolean {
        return this.state.isVerified;
    }

    /**
     * Reload the company details from server.
     */
    public reloadSettings(): void {
        if (Store.user.user?.companyUuid) {
            const payload: Record<string, any> = {};
            payload.uuid = Store.user.user.companyUuid;

            let promise: Bluebird<any> = Main.callApi('company/layout', RequestMethods.GET, payload, CompanyLayoutDTO);
            promise = promise.then(this.handleResult.bind(this));
        } else {
            this.handleResult(null);
        }
    }

    private handleResult(result: CompanyLayoutDTO): void {
        this.state.name = result?.name;
        this.state.slug = result?.slug;
        this.state.avatar = result?.avatar;
        this.state.registrationCountry = result?.registrationCountry;
        this.state.unreadMessagesCount = result?.unreadMessagesCount;
        this.state.subscriptionType = result?.subscriptionType;
        this.state.availabilityStatus = result?.availabilityStatus;

        Main.initiateFavicon(this.unreadMessagesCount);
    }

    private handleUpdateStatusResult(): void {
        Main.app.toast.success(Main.trans.t(Lang.t.app.toast.success.availabilityStatusUpdated.message), Main.trans.t(Lang.t.app.toast.success.availabilityStatusUpdated.title))
    }

}
