import {Component, Prop, Vue} from 'vue-facing-decorator';
import {PublicCatalogueServiceDTO} from '@/app/dto/PublicCatalogueServiceDTO';
import {IconParseTypes} from "@/app/constants/IconParseTypes";

@Component({})
export default class ProductCard extends Vue {

    @Prop({type: Boolean, default: true})
    public readonly hasShadow: boolean;

    @Prop({type: Object, default: null})
    public readonly service: PublicCatalogueServiceDTO;

    public readonly IconParseTypes = IconParseTypes;

    public created(): void {
    }

    public mounted(): void {
    }

}

