<script lang="ts" src="./ServiceCategoryIcons.ts"></script>

<template>
  <div :class="[{'flex z-10': !withLabel }]">
    <ul :class="['flex flex-row space-x-2', { 'flex-wrap -mt-2 -ml-2': withLabel }]">
      <li v-for="(serviceCategory, index) in serviceCategories.slice(0, maxIcons)" :class="[{'inline-flex mt-2 ml-2': withLabel }]">
        <m-tooltip v-if="!withLabel" :label="$trans.t('meta.serviceCategoryTypes.' + serviceCategory)" class="tooltip">
          <m-button
            :appearance="appearance"
            :isLight="isLight"
            :shape="hasShape"
            :size="size"
            :variant="variant"
          >
            <m-icon :name="serviceCategory" :parse-to="IconParseTypes.SERVICE_CATEGORY" :size="$const.StyleSize.XSMALL" :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="my-auto mr-1"/>
          </m-button>
        </m-tooltip>

        <m-button
          v-else
          :appearance="appearance"
          :icon-before="{ name: getParsedValue(serviceCategory), solid: true, type: $const.IconTypes.FONT_AWESOME, }"
          :isLight="isLight"
          :shape="hasShape"
          :size="size"
          :variant="variant"
        >
          {{ $trans.t('meta.serviceCategoryTypes.' + serviceCategory) }}
        </m-button>
      </li>

      <li :class="[{'inline-flex mt-2 ml-2': withLabel }]">
        <m-button
          v-if="serviceCategories.length > maxIcons"
          :appearance="$const.StyleAppearance.DISABLED"
          :isLight="isLight"
          :shape="hasShape"
          :size="size"
          :variant="variant"
          class="body-font"
          @click="openList()"
        >
          + {{ serviceCategories.length - maxIcons }}
        </m-button>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
</style>
